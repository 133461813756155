<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>
      <Lightbox ref="lightbox"/>
      <ConfirmDialog ref="confirmDialog"/>


      <v-row justify="center">

        <v-col cols="12" lg="10">
          <GiftyTabs/>
        </v-col>

        <v-col cols="12" lg="10">
          <v-card class="transparent" flat>

            <form @submit.prevent="searchPos">
              <div class="d-flex">
                <v-text-field v-model.trim="code"
                              :error-messages="errors.code"
                              background-color="white"
                              class="search-input flex-column flex"
                              filled
                              placeholder="Entrez le code pos ici..."
                              prepend-inner-icon="mdi-magnify"
                              required
                              rounded
                              @input="errors.code = ''"
                >
                </v-text-field>
                <v-btn class="btn-search"
                       color="primary"
                       depressed
                       height="56"
                       type="submit">
                  Recherche
                </v-btn>
              </div>
            </form>

            <v-card v-if="Object.keys(salepoint).length" class="shadow rounded-lg">
              <v-card-text class="pa-6 pa-lg-10">

                <div class="text-center mb-lg-8 mb-5">
                  <v-avatar v-if="salepoint.image" size="100">
                    <v-img :src="$baseUrl + salepoint.image"
                           class="zoom-pointer"
                           @click="$refs.lightbox.open(salepoint.image)"></v-img>
                  </v-avatar>

                  <v-avatar v-else
                            :class="$func.randomColor()"
                            class="font-weight-medium avatar-border"
                            size="100">
                     <span class="fs-20">
                       {{ $func.avatarName(salepoint.name) }}
                     </span>
                  </v-avatar>
                </div>

                <v-simple-table class="rounded-lg table-border">
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon dense left>mdi-code-array</v-icon>
                        Code du pos
                      </td>
                      <td class="primary--text font-weight-medium">{{ salepoint.code }}</td>
                    </tr>
                    <tr class="grey lighten-4">
                      <td class="font-weight-medium">
                        <v-icon dense left>mdi-account</v-icon>
                        Nom du pos
                      </td>
                      <td>{{ salepoint.name }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">
                        <v-icon dense left>mdi-store</v-icon>
                        Magasin
                      </td>
                      <td>{{ salepoint.store_name }}</td>
                    </tr>
                    <tr class="grey lighten-4">
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon dense left>mdi-phone</v-icon>
                        N° de téléphone
                      </td>
                      <td>{{ salepoint.phone }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">
                        <v-icon dense left>mdi-map-marker</v-icon>
                        Adresse
                      </td>
                      <td>{{ salepoint.address }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>


                <v-btn v-if="!salepoint.has_gifty_account"
                       :to="'/supervisor-view/gifty/'+salepoint.id+'/liaison'"
                       block
                       class="mt-5"
                       color="primary"
                       depressed>
                  Lié avec Gifty
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>

                <v-simple-table v-if="salepoint.has_gifty_account"
                                class="rounded-lg table-border mt-3">
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td class="font-weight-medium text-no-wrap py-4" colspan="2">
                        <v-avatar>
                          <GiftyIcon/>
                        </v-avatar>
                        Information du compte GIFTY
                      </td>
                    </tr>
                    <tr class="grey lighten-4">
                      <td class="font-weight-medium">
                        <v-icon dense left>mdi-account</v-icon>
                        Nom complet
                      </td>
                      <td>{{ giftyAccount.name }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon dense left>mdi-phone</v-icon>
                        N° de téléphone
                      </td>
                      <td>{{ giftyAccount.phone }}</td>
                    </tr>
                    <tr class="grey lighten-4">
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon dense left>mdi-calendar-heart</v-icon>
                        Date de naissance
                      </td>
                      <td>{{ giftyAccount.dob }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon dense left>mdi-gender-male-female</v-icon>
                        Sexe
                      </td>
                      <td>{{ giftyAccount.gender === 'male' ? 'Homme' : 'Femme' }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <div v-if="salepoint.has_gifty_account && salepoint.reward_count < 2"
                     class="mt-5">
                  <RewardBtn :code.sync="salepoint.code"
                             :reward_count="salepoint.reward_count"
                             @reset="[code = '',salepoint = {}]"/>
                </div>

                <v-alert v-if="salepoint.has_gifty_account && salepoint.reward_count >= 2"
                         border="bottom"
                         class="mb-0 fs-14 mt-5"
                         color="info"
                         dark>
                  - Ce point de vente est lié à l'application <span class="font-weight-medium">Gifty.</span>
                  <br>
                  - <span class="font-weight-medium">Note : </span>
                  Vous ne pouvez pas envoyer de récompense aujourd'hui car leur nombre est égal à
                  <span class="font-weight-medium">[ 2 ].</span>
                </v-alert>

              </v-card-text>
            </v-card>

            <v-alert v-if="noPos"
                     border="bottom"
                     class="mb-0 fs-14"
                     dense
                     dismissible
                     prominent
                     type="warning">
              <p class="mb-0">OOPS ! Aucun résultat trouvé. Veuillez saisir un code pos valide.</p>
            </v-alert>


            <v-card-text v-if="!Object.keys(salepoint).length"
                         class="text-center">

              <div class="text-center">
                <img :src="require('@/assets/search.svg')" alt="" width="250"/>
              </div>

              <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
                La laison avec GIFTY.
              </h1>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </p>

            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import RewardBtn from "@/views/supervisor-view/gifty/components/RewardBtn.vue";
import GiftyTabs from "@/views/supervisor-view/gifty/components/GiftyTabs.vue";
import axios from "axios";
import GiftyIcon from "@/components/svg-icons/GiftyIcon.vue";

export default {
    components: {GiftyIcon, GiftyTabs, RewardBtn},
    data() {
        return {
            overlay: false,
            noPos: false,
            code: '',
            salepoint: {},
            giftyAccount: {},
            errors: {}
        }
    },
    methods: {
        searchPos() {
            this.errors = {}
            this.salepoint = {}
            this.overlay = true
            this.noPos = false
            HTTP.get('/gifty/search-pos', {
                params: {
                    code: this.code
                }
            }).then((res) => {

                this.overlay = false

                if (res.data.data) {
                    this.salepoint = res.data.data
                    /**
                     * Get Gifty Account
                     */
                    if (this.salepoint.has_gifty_account) {
                        this.getGiftyAccount(this.salepoint.salepoint_gifty.gifty_id)
                    }
                } else {
                    this.noPos = true
                }
            }).catch(err => {
                this.overlay = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        getGiftyAccount(giftyId) {
            this.overlay = true
            axios.get(process.env.VUE_APP_GIFTY_URL + '/api/account/' + giftyId).then((res) => {
                this.giftyAccount = res.data.user
                this.overlay = false
            }).catch(err => {
                console.log(err)
                this.overlay = false
            })
        }
    }
}
</script>

<style>
.btn-search {
    border-radius: 0 28px 28px 0;
}

.search-input v-text-field .v-input__control, .search-input.v-text-field .v-input__slot, .search-input.v-text-field fieldset {
    border-radius: 28px 0 0 28px;
}
</style>